.bg {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(16,34,55,0.85);
    .modal {
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translate(-50%);
        width: 1200px;
        color: #fff;
        text-align: center;
        background: #102237;
        border: 1px solid #FFFFFF;
        border-radius: 10px;
        font-size: 16px;
        .title {
            line-height: 50px;
            border-bottom: 1px solid #82868B;
        }
        .content {
            margin-top: 28px;
            margin-bottom: 50px;
        }
        .btn-grp {
            margin-bottom: 25px;
            button {
                width: 110px;
                line-height: 30px;
                background: #485A7E;
                color: #fff;
                border: none;
                border-radius: 4px;
                &+button {
                    margin-left: 20px;
                }
                &.primary {
                    background: #0E60F5;
                }
            }
        }
        .close {
            position: absolute;
            right: 14px;
            top: 0;
            font-size: 21px;
        }
    }
}