.bg {
    background: #0a304a url('./bg.png');
    height: 100vh;
    width: 100vw;
    background-size: 100% 100%;
    position: relative;

    .wrap-border {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: -60px;
        width: 554px;
        padding: 2px;
        border-radius: 10px;
        background: linear-gradient(0deg, #3BA4C2, rgba(28, 130, 189, 0));
        .wrap {
            text-align: center;
            background: linear-gradient(0deg, #172F4C 0%, rgba(40, 128, 212, 0) 100%);
            box-shadow: 0px 33px 106px 0px rgba(6, 21, 38, 0.23);
            border-radius: 10px;
            padding: 65px 0;
            .content {
                text-align: left;
                width: 360px;
                display: inline-block;
                .logo {
                    width: 360px;
                }
                .title {
                    font-size: 22px;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin-top: 56px;
                }
                .input-item {
                    margin-top: 20px;
                    position: relative;
                    .input {
                        background-color: transparent;
                        border-left: none;
                        border-top: none;
                        border-right: none;
                        border-bottom: 1px solid #FFFFFF;
                        color: #FFFFFF;
                        border-radius: 0;
                        padding: 13px 11px 13px 0;
                        font-size: 18px;
                        .icon-input {
                            height: 22px;
                            background-size: 100% 100%;
                            margin-right: 5px;
                        }
                        .icon-phone {
                            width: 18px;
                            background-image: url('./phone.svg');
                        }
                        .icon-verifyCode {
                            width: 20px;
                            background-image: url('./verifyCode.svg');
                        }
                        input::placeholder {
                            color: #6E8EAE;
                        }
                    }
                    .input-verifyCode {
                        padding-right: 10px;
                    }
                    .btn-verifyCode-wrap {
                        border-bottom: 1px solid #FFFFFF;
                        border-radius: 0;
                        .btn-verifyCode {
                            width: 100px;
                            height: 29px;
                            background: #0EF2F5;
                            border-radius: 13px;
                            font-size: 16px;
                            color: #102237;
                            line-height: 29px;
                            border: none;
                            margin-top: 13px;
                            text-align: center;
                            padding: 0;
                            &:disabled {
                                background: #20A2A4;
                            }
                        }
                    }
                }
                .bottom-wrap {
                    margin-top: 80px;
                    position: relative;
                    .error-msg {
                        position: absolute;
                        color: #F8A436;
                        font-size: 14px;
                        top: -27px;
                    }
                    .btn-login {
                        background-color: #105CE4;
                        border-radius: 4px;
                        height: 56px;
                        line-height: 56px;
                        width: 100%;
                        text-align: center;
                        color: #FFFFFF;
                        font-size: 22px;
                        &:hover, &:active {
                            background-color: #0D4BBA;
                        }
                    }
                }
            }
        }
    }
}