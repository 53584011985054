.banner {
    height: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    box-sizing: border-box;
    background: #102237;
    z-index: 200;
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;
    .vin-selector {
        height: 100%;
        border-left: 1px solid #82868B;
        border-right: 1px solid #82868B;
        width: 250px;
        padding-left: 12px;
        padding-right: 12px;
        box-sizing: border-box;
        font-size: 16px;
        position: relative;
        .current-vin {
            z-index: 1;
            color: #FFFFFF;
            text-align: center;
            em {
                font-weight: 500;
                font-style: normal;
                font-size: 40px;
                margin-right: 10px;
            }
            span {
                font-size: 16px;
            }
        }
    }
    .traffic-light {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .router-speed {
        position: absolute;
        right: 130px;
        .router, .speed {
            display: inline-block;
            padding: 0 16px;
            vertical-align: middle;
        }
        .router {
            border-left: 1px solid #82868B;
        }
    }
    .close {
        position: absolute;
        right: 23px;
        width: 21px;
        height: 21px;
        background-size: 100% 100%;
        background-image: url('./close.png');
    }
}