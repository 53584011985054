.traffic-light {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 128px;
    height: 42px;
    border-radius: 21px;
    background-color: #1A3452;
    .light {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin: 0 4px;
        &.green {
            background: #73F662;
        }
        &.red {
            background: red;
        }
        &.yellow {
            background: yellow;
        }
        &.black {
            background: black;
        }
        &.empty {
            background: rgb(170, 170, 170);
        }
    }
}