.no-vehicle-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .no-vehicle {
        color: #ADC9DC;
        font-size: 16px;
    }
}

.vehicle-item {
    margin: 18px auto;
    width: 206px;
    height: 92px;
    background: linear-gradient(-90deg, #2A3E76 0%, #334677 100%);
    border-radius: 4px;
    padding: 10px 0;
    border: 1px solid transparent;
    color: #FFFFFF;
    line-height: 1;
    text-align: center;

    &:hover {
        border-color: #737EE6;
    }

    .vehicle-item-name {
        font-size: 30px;
        margin-bottom: 14px;
        padding-top: 5px;
    }

    .vehicle-item-power {
        font-size: 14px;
    }

    .vehicle-item-right {
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        text-align: left;
        padding-left: 13px;
        font-size: 16px;

        .vehicle-item-state {
            padding-top: 7px;
            margin-bottom: 20px;
            line-height: 1;
        }
    }
}

.vehicle-item-rest {
    background: linear-gradient(-90deg, #11213A 0%, #132D3E 100%);
    color: #8C949F;
}

.vehicle-item-active {
    background: linear-gradient(-90deg, #373DBF 0%, #4A53D3 100%);

    .vehicle-item-name,
    .vehicle-item-power {
        color: #FFFFFF;
    }
}