.router-ping-info {
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
    line-height: 1;
    .label {
        margin-bottom: 10px;
    }
    .ccyan {
        color: #0EF2F5;
    }
    .icon {
        margin-right: 8px;
        background-image: url('./connect.svg');
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-size: cover;
    }
}