.camera {
    height: 100%;
    width: 100%;
    position: relative;
    background: linear-gradient(0deg, #091221 0%, #1768D2 46%, #0A2A60 99%);
    box-shadow: 0px 0px 10px 0px rgba(16, 34, 55, 0.35);
    .video {
        width: 100%;
        height: 100%;
        video {
            width: 100%;
        }
    }
    .unable {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 45px;
        width: 163px;
        color: rgba(255, 255, 255, 0.5);
        line-height: 28px;
        font-size: 18px;
        text-align: center;
        z-index: 100;
    }
    .stop-btn, .play-btn {
        height: 27px;
        width: 32px;
        background: rgba(11, 18, 28, 0.3);
        box-shadow: 0px 2px 5px 0px rgba(91, 7, 7, 0.35);
        border-radius: 2px;
        position: relative;
        position: absolute;
        bottom: 16px;
        right: 9px;
        z-index: 100;
        &::before {
            content: '';
            display: block;
            height: 15px;
            width: 11px;
            background: url('./assert/stop.png');
            background-size: 100% 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .play-btn {
        &::before {
            background-image: url('./assert/play.png');
        }
    }
    .camera-title {
        position: absolute;
        left: 12px;
        top: 12px;
        font-size: 12px;
        color: #FFFFFF;
        z-index: 100;
    }
    &:nth-child(4), &:nth-child(5), &:nth-child(6) {
        margin-top: 2px;
    }
    canvas {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.camera-grp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    background-color: #111;
    overflow: hidden;
    position: relative;
    >* {
        width: 33.2%;
        height: 24.737945492662475%;
    }
    >:nth-child(1) {
        width: 100%;
        height: 75.26205450733752%;
    }
}

.camera-exception, .camera-getting, .camera-vehicle-close {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
}
.camera-exception::before, .camera-getting::before, .camera-vehicle-close::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fade 2000ms infinite;
    font-size: 16px;
}
.camera-exception::before, .camera-getting::before {
    content: '画面暂停\A点击播放尝试恢复';
    white-space: pre;
    color: red;
    font-weight: 500;
    border: 2px solid;
    padding: 17px 30px;
    text-align: center;
}
.camera-getting::before {
    content: '连接中...';
}
.camera-vehicle-close::before {
    content: '车辆已关机';
    color: #91BAEF;
}