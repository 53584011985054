*::-webkit-scrollbar-thumb {
    background: #0E60F5;
    border-radius: 4px;
    
}

*::-webkit-scrollbar {
    background: transparent;
    width: 8px;
}

body {
    font-family: arial;
    user-select: none;
}

// @font-face {
//     font-family: 'iconfont';  /* Project id 3867537 */
//     src: url('//at.alicdn.com/t/c/font_3867537_ycogeuravw.woff2?t=1686284805919') format('woff2'),
//             url('//at.alicdn.com/t/c/font_3867537_ycogeuravw.woff?t=1686284805919') format('woff'),
//             url('//at.alicdn.com/t/c/font_3867537_ycogeuravw.ttf?t=1686284805919') format('truetype');
// }

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}