.page-wrap {
    display: flex;
    background-color: #264254;
    padding-top: 1px;
    .page-left {
        width: 252px;

        .vehicle-group {
            background-color: #1F3747;
            height: calc(100vh - 121px);
            &::-webkit-scrollbar {
                width: 8px;
                background-color: #264254;
            }
            &::-webkit-scrollbar-thumb {
                background: #0E60F5;
                border-radius: 4px;
            }
            overflow-y: scroll;
        }
    }
    .page-right {
        flex: 1;
    }
}