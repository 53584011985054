.header {
    height: 60px;
    background: #102237;
    position: relative;

    .logo {
        height: 25px;
        margin-top: 18px;
        position: absolute;
        left: 27px;
    }
    .button-wrap {
        position: absolute;
        right: 22px;
        top: 23px;
    }
}