.map {
    height: 100%;
    width: 100%;
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(16, 34, 55, 0.2);
        pointer-events: none;
    }
    .gd-map-container {
        height: 100%;
        width: 100%;
    }
    .station-wrapper {
        position: absolute;
        top: 20px;
        left: 24px;
        right: 24px;
        height: 32px;
    }
}

.station-selector {
    height: 40px;
    line-height: 40px;
    border: 1px solid #DBCBFA;
    border-radius: 4px;
    background: rgba(9, 19, 27, 0.9);
    color: rgba(255, 255, 255, 0.4);
    padding-left: 35px;
    position: relative;
    input {
        background: transparent;
        border: none;
        width: 80%;
        outline: none;
        color: rgba(255, 255, 255, 0.4);
    }
    &::before {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        content: '';
        display: block;
        height: 18px;
        width: 18px;
        background: url('./assert/search.png');
        background-size: 100% 100%;
    }
    button {
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
        height: 33px;
        border: none;
        background: rgba(87, 17, 215, 0.9);
        color: #fff;
        border-radius: 4px;
    }
}

.options {
    
    max-height: 300px;
    overflow: auto;
    background: rgba(255,255,255, .1);
    .option {
        line-height: 30px;
        padding-left: 1em;
        color: rgba(255, 255, 255, 0.4);
        &.active {
            color: #fff;
        }
    }
}