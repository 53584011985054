.search-box-wrap {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    padding-left: 50px;
    position: relative;
    &::before {
        position: absolute;
        top: 12px;
        left: 16px;
        content: '';
        height: 17px;
        width: 17px;
        display: block;
        background: url('./search.png');
        background-size: 100% 100%;
    }
}
.search-box {
    width: 100%;
    box-sizing: border-box;
    background: transparent;
    border: none;
    line-height: 40px;
    outline: none;
    color: #fff;
    caret-color: #0E60F5;
    font-size: 16px;
}