.innerShop-switch {
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 40px;
    background: #102237;
    position: relative;
    z-index: 1;
    padding: 10px 0;

    .wrapper {
        display: flex;
        color: #fff;
        position: relative;
        flex-grow: 1;
        flex-shrink: 1;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 50px;
            background: #031122;
            display: block;
        }

        .mask {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: transparent;
            z-index: 1;
        }

        .list {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background: rgba(16, 34, 55, 0.9);
            z-index: 10000;
            height: calc(100vh - 121px);

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                height: 100%;
                padding: 20px 10px;
                box-sizing: border-box;
                overflow-y: scroll;

                &::-webkit-scrollbar-thumb {
                    background: #0E60F5;
                    border-radius: 4px;
                }

                &::-webkit-scrollbar {
                    background-color: transparent;
                }

                .search-box {
                    width: 100%;
                    margin: auto;
                    margin-bottom: 10px;
                }

                li {
                    width: 100%;
                    text-align: center;
                    background: #173150;
                    border: 1px solid #B0BDDC;
                    border-radius: 2px;
                    margin: auto;
                    box-sizing: border-box;
                    padding: 7px 20px;
                    color: #C5C6C7;

                    &+li {
                        margin-top: 10px;
                    }

                    &.active,
                    &:hover {
                        color: #0EF2F5;
                        border-color: #0EF2F5;
                    }

                    &>div {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        line-height: 24px;
                    }
                }

                button {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    background: #2D5CF5;
                    border-radius: 2px;
                    margin: 13px auto;
                    display: block;
                    border: none;
                    color: #FFFFFF;
                    font-size: 16px;
                }
            }
        }

        .current {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding-right: 55px;
            padding-left: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 20px;
        }
    }
}

.select {
    height: 60px;
    flex-grow: 1;
    padding-right: 45px;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;

    &::after {
        position: absolute;
        top: 50%;
        right: 26px;
        transform: translateY(-50%);
        content: '';
        display: block;
        height: 9px;
        width: 13px;
        background: url('./down.png');
        background-size: 100% 100%;
    }

    &.down {
        &::after {
            transform: rotate(180deg);
        }
    }
}