.order-panel {
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 40px;
    background: #102237;
    position: relative;
    z-index: 1;
    padding: 10px 0;
    
    .order {
        border-left: 1px solid #82868B;
        display: flex;
        color: #fff;
        position: relative;
        flex-grow: 1;
        flex-shrink: 1;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 50px;
            background: #031122;
            display: block;
        }

        .mask {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
        }

        .list {
            position: absolute;
            z-index: 10;
            left: 0;
            right: 0;
            top: 50px;
            overflow: auto;
            overflow-x: hidden;
            max-height: 292px;
            background: rgba(16,34,55,0.9);
            border-radius: 0px 0px 4px 4px;

            &::-webkit-scrollbar-thumb {
                background: #0E60F5;
                border-radius: 4px;
            }

            .list-body {
                height: 180px;
                overflow: auto;
            }
        }

        .current {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding-right: 45px;
            &>div {
                display: inline-block;
                margin-right: 10px;
                vertical-align: middle;
            }
            .status {
                display: inline-block;
                color: #0EF2F5;
                position: relative;
                top: 1px;
                padding-right: 5px;
            }

            .icon {
                vertical-align: middle;
                font-size: 16px;
            }

            .text {
                vertical-align: middle;
                margin-left: 5px;
            }
        }
    }

    .label {
        width: 80px;
        text-align: center;
        flex-shrink: 0;
        line-height: 40px;
    }
    .icon-line {
        line-height: 40px;
        color: #82868B;
        margin-right: 12px;
    }
}

.select {
    flex-grow: 1;
    padding-right: 45px;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;

    .oneline {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
    }

    &::after {
        position: absolute;
        top: 50%;
        right: 26px;
        transform: translateY(-50%);
        content: '';
        display: block;
        height: 9px;
        width: 13px;
        background: url('./down.png');
        background-size: 100% 100%;
    }

    &.down {
        &::after {
            transform: rotate(180deg);
        }
    }
}

.order-item {
    background: #294162;
    border-radius: 7px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 11px;
    margin-bottom: 11px;
    padding: 10px 95px 10px 10px;
    position: relative;

    &>div {
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
    }

    .icon-zhandian,
    .icon-baoguo {
        font-size: 12px;
    }

    &.active {
        background: #0F5766;
        border: 1px solid #0EF2F5;
        color: #0EF2F5;

        .address-line,
        .station-line {
            color: #0EF2F5;
        }
    }

    &.order-item-travelplan {
        font-size: 12px;

        .mbxs {
            margin-bottom: 5px;
        }

        .mr12 {
            margin-right: 12px;
        }

        .title {
            margin-left: 5px;
            margin-right: 10px;
        }

        .left-time {
            position: absolute;
            right: 14px;
            bottom: 10px;
            text-align: right;
        }
    }

    &+.order-item {
        margin-top: 11px;
    }

    .address-line,
    .station-line {
        font-weight: 400;
        color: #B3B9C1;
        line-height: 16px;
        font-size: 12px;
        padding-right: 20px;
        padding-left: 14px;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            height: 12px;
            width: 8px;
            left: 0;
            top: 2px;
            background-size: 100% 100%;
        }
    }

    .address-line::before {
        background-image: url('./address.png');
    }

    .station-line::before {
        background-image: url('./station.png');
    }
}

.ccyan {
    color: #0EF2F5;
}