.select-page {
    width: 100%;
    height: 100%;
    background: url('./high-light.png');
    background-color: #0A304A;
    background-size: cover;
    background-position: center;
    position: relative;

    &::before {
        content: '';
        background: url('./vehicle.png');
        background-size: 100% 100%;
        position: absolute;
        width: 587px;
        height: 503px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .vin-control {
        display: flex;
        height: calc(100vh - 61px);

        .vin-left {
            flex: 1;
            position: relative;

            .map {
                position: absolute;
                top: 0px;
                left: 0;
                right: 0;
                bottom: -20px;
                z-index: 0;
            }
        }

        .vin-right {
            width: calc(100vh - 121px);

            .camera-grp {
                height: calc(100vh - 121px);
                position: relative;
            }
        }
    }
}